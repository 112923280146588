<template>
  <!-- Gráfico  -->
  <!-- <apexCharts class="apexchart" height="100%" :options="chartOptions" :series="series" type="donut" /> -->
  <apexCharts type="donut" width="324" :options="chartOptions" :series="series"></apexCharts>
</template>
<script>
import ThemeMixin from '@/mixins/Theme.vue';
import 'dayjs/locale/pt-br';

export default {
  name: 'GraficoAgentesNocivos',
  components: {
    ApexCharts: () => import('vue-apexcharts'),
  },
  mixins: [ThemeMixin],

  data() {
    return {
      series: [1],
      // series: [55, 5, 5, 10, 20, 5],
      chartOptions: {
        chart: {
          height: '206px',
          type: 'donut',
          animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 1600,
            animateGradually: {
              enabled: false,
              delay: 0,
            },
            dynamicAnimation: {
              enabled: false,
              speed: 350,
            },
          },
        },
        dataLabels: {
          enabled: false,
          dropShadow: {
            blur: 3,
            opacity: 0.8,
          },
        },

        tooltip: {
          fillSeriesColor: false,
          followCursor: false,
          enable: true,
          style: {
            fontSize: '12px',
            fontFamily: 'Roboto, sans-serif',
            backgroundColor: '#52526B',
            paddingLeft: '8px !important',
            paddingRight: '8px !important',
          },
          //    y: { formatter: function(value) { return value + "your text"; } } ,
          custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            let total = 0;
            for (let x of series) {
              total += x;
            }

            let selected = series[seriesIndex];
            let html =
              ' <div style="margin-top: 4px; margin-bottom: 4px; "><div style=" text-indent: 5 px;  margin-right: 8px;border-radius: 8px; width: 10px;  height: 10px; background-color: ' +
              w.config.colors[seriesIndex] +
              '; display: inline-block;">' +
              '</div>' +
              '<div style="display: inline-block; border-radius: 8px">' +
              w.config.labels[seriesIndex] +
              ' (' +
              ((selected / total) * 100).toFixed(2) +
              '%)' +
              '</div></div>';
            //   w.config.labels[seriesIndex] + ' (' + ((selected / total) * 100).toFixed(2) + '%)';
            return html;
          },
        },

        plotOptions: {
          pie: { offsetY: 18, customScale: 0.7 },
        },

        labels: ['Nenhum dado disponível'],
        legend: {
          position: 'bottom',
          offsetY: -25,
          showForSingleSeries: false,
          showForZeroSeries: true,
        },
        colors: ['#E7E7FA'],
      },
    };
  },
  watch: {
    options: {
      handler() {},
    },
  },
};
</script>
<style scoped>
#cardBars {
  height: 380px;
  border: 1px solid #e7e7fa !important;
  background: #f9fafb !important;
  border-radius: 4px !important;
}
.wrapper {
  min-height: 380px !important;
  height: 380px !important;
  color: var(--v-primary-base);
}
</style>
